<template>
  <div>
    <div class="container-fluid">
      <b-col class=" card shadow border border-white w-50 p-2 m-5 bg-white" style="margin-left: 25% !important;">
        <div class="text-center m-2 p-2">
          <h3>Auditor Dashboard</h3>
          <span> <b-link @click="logOutAuditor" class="text-danger">Logout</b-link> </span>
        </div>
        <b-alert :show="allPermissionsAreExpired" variant="danger">Currently you do not have permission to access audit
          access or the permission has expired</b-alert>

        <b-form-group>
          <label class="required-label" for="">Choose Institution:</label>
          <b-form-select v-model="institution_id" :options="institutions" @change="institutionChanged" class="mb-3"
            value-field="id" text-field="name"></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="required-label" for="">Programme (Scheme):</label>
          <b-form-select @change="usergroupChanged" :disabled="(institution_id == null)" v-model="usergroup_id"
            class="mb-3">
            <b-form-select-option :value="null">Please select a Programme</b-form-select-option>
            <b-form-select-option v-for="(auditorGroup, reindex) in institutionsAuditorUsergroups[institution_id]"
              :key="reindex" :value="auditorGroup.usergroup_id">
              {{ usergroups[auditorGroup.usergroup_id].name }}-
              {{ auditorGroup.academic_year }}.{{ auditorGroup.academic_semester }}
              ({{ usergroups[auditorGroup.usergroup_id].scheme ? usergroups[auditorGroup.usergroup_id].scheme : ''
              }})</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="required-label" for="">Class:</label>
          <b-form-select @change="usersubgroupChanged" :disabled="(usergroup_id == null)" v-model="usersubgroup_id"
            class="mb-3">
            <b-form-select-option :value="null">Please select </b-form-select-option>
            <b-form-select-option v-for="(subgroup, coindex) in usersubgroupsAndCourses" :key="coindex"
              :value="subgroup.id">{{ subgroup.name }}-{{ subgroup.code }}</b-form-select-option>
          </b-form-select>
        </b-form-group>


        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="required-label" for="">Course:</label>
          <b-form-select @change="courseChanged" :disabled="(usersubgroup_id == null)" v-model="course_id" class="mb-3">
            <b-form-select-option :value="null">Please select </b-form-select-option>
            <b-form-select-option v-for="(course, coindex) in selectedSubgroupCourses" :key="coindex"
              :value="course.id">{{ course.name }}-{{ course.code }}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <div class="text-center">
          <b-spinner class="text-center" v-if="loadData" variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </b-col>

      <b-col class="mb-5" v-if="selectedCourse">
        <div class=" card shadow border border-white  p-2  bg-white mb-4 scrollable-tabs">
          <h5 class="text-center">Report of {{ selectedCourse.name }} {{ selectedCourse.code }}- {{
              selectedSubgroup.name
          }}
            {{ selectedSubgroup.code }}</h5>
          <b-tabs pills card vertical v-model="currentCourseTabIndex">
            <b-tab @click="courseReportTabChanged(report_data)" v-for="(report_data, reindex) in course_reports_data"
              :key="reindex">
              <template #title>
                <span :class="{ 'text-danger': report_data.config === false }"
                  v-b-popover.hover.top="(report_data.config === false ? 'This report is not yet generated by the concerned faculty' : '')">
                  {{ (reindex + 1) }}.{{
                      report_data.name
                  }}</span>
              </template>
              <b-card-text v-if="(coursePdfReportKey == report_data.key)">
                <div class="text-center">
                  <b-spinner class="text-center" v-if="loadData" variant="primary" type="grow"
                    label="Spinning"></b-spinner>
                </div>
                <iframe :src="coursePdfUrl" frameborder="0" width="100%" style="height: 98vh;"></iframe>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>


        <div class=" card shadow border border-white  p-2  bg-white mb-4" v-if="selectedSubgroup">
          <h5 class="text-center">Report of {{ selectedSubgroup.name }} {{ selectedSubgroup.code }}</h5>

          <b-tabs pills card vertical v-model="currentSubgroupTabIndex">
            <b-tab @click="subgroupReportTabChanged(report_data)"
              v-for="(report_data, reindex) in subgroup_reports_data" :key="reindex">
              <template #title>
                <span :class="{ 'text-danger': report_data.config === false }"
                  v-b-popover.hover.top="(report_data.config === false ? 'This report is not yet generated by the concerned faculty' : '')">
                  {{ (reindex + 1) }}.{{
                      report_data.name
                  }}</span>
              </template>
              <b-card-text v-if="(subgroupPdfReportKey == report_data.key)">
                <div class="text-center">
                  <b-spinner class="text-center" v-if="loadData" variant="primary" type="grow"
                    label="Spinning"></b-spinner>
                </div>
                <iframe :src="subgroupPdfUrl" frameborder="0" width="100%" style="height: 98vh;"></iframe>
              </b-card-text>
            </b-tab>
          </b-tabs>

        </div>
      </b-col>

    </div>
  </div>
</template>

<script>

export default {
  props: [],
  async created() {
    var auditorAuthToken = localStorage.getItem("auditorAuthToken");
    if (!auditorAuthToken) {
      this.$router.push('/auditor/login');
    } else {
      this.getUsergroupsAndInstitutions();
    }
  },
  components: {},
  data() {
    return {
      loadData: false,

      institutions: [],
      institution_id: null,
      institutionsAuditorUsergroups: {},
      usergroups: {},
      usergroup_id: null,
      usersubgroupsAndCourses: [],
      usersubgroup_id: null,
      course_id: null,
      selectedSubgroup: null,
      selectedCourse: null,
      subgroup_reports_data: [],
      course_reports_data: [],

      currentCourseTabIndex: null,
      coursePdfReportKey: '',
      coursePdfUrl: null, // generated pdf url of downloaded pdf to show in view

      currentSubgroupTabIndex: null,
      subgroupPdfReportKey: '',
      subgroupPdfUrl: null,

      allPermissionsAreExpired: false,
    }
  },
  computed: {
    selectedSubgroupCourses() {
      let courses = [];
      this.usersubgroupsAndCourses.forEach(subgroup => {
        if (subgroup.id == this.usersubgroup_id) courses = subgroup.courses;
      });
      return courses;
    },
  },
  methods: {
    async usersubgroupChanged() {
      this.loadData = true;
      this.subgroup_reports_data = [];
      this.course_reports_data = [];
      this.course_id = null;
      let selectedSubgroup = this.usersubgroupsAndCourses.find(obj => obj.id == this.usersubgroup_id);
      let selectedCourses = selectedSubgroup.courses;

      this.selectedSubgroup = {
        id: selectedSubgroup.id,
        name: selectedSubgroup.name,
        code: selectedSubgroup.code,
      };
      if (selectedCourses.length > 0) {
        this.course_id = selectedCourses[0].id;
        this.selectedCourse = selectedCourses[0];
        await this.getCourseReportOptions();
      }
      await this.getSubgroupReportOptions();
      this.loadData = false;
    },
    async subgroupReportTabChanged(report_data) {
      this.loadData = true;
      this.subgroupPdfReportKey = report_data.key;
      this.subgroupPdfUrl = null;
      await this.getSubgroupPdfReport();
      this.loadData = false;
    },
    async getSubgroupPdfReport() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/auditor/usersubgroups/report/pdfexport`;
      try {
        const response = await this.$axios.post(url, { usersubgroup: this.usersubgroup_id, report_key: this.subgroupPdfReportKey }, { responseType: 'arraybuffer' });
        const pdfData = new Blob([response.data], { type: 'application/pdf' });
        this.subgroupPdfUrl = URL.createObjectURL(pdfData);
      } catch (error) {
        console.error('Error fetching PDF from API:', error);
      }
    },
    async courseReportTabChanged(report_data) {
      this.loadData = true;
      this.coursePdfReportKey = report_data.key;
      this.coursePdfUrl = null;
      await this.getCoursePdfReport();
      this.loadData = false;
    },
    async getCoursePdfReport() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/auditor/courses/report/pdfexport`;
      try {
        const response = await this.$axios.post(url, { course: this.course_id, report_key: this.coursePdfReportKey }, { responseType: 'arraybuffer' });
        const pdfData = new Blob([response.data], { type: 'application/pdf' });
        this.coursePdfUrl = URL.createObjectURL(pdfData);
      } catch (error) {
        console.error('Error fetching PDF from API:', error);
      }
    },

    async getCourseReportOptions() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/auditor/courses/reports/options`;
      await this.$axios
        .post(url, { course: this.course_id })
        .then((response) => {
          this.course_reports_data = response.data.course_reports_data;
        })
        .catch((error) => {
          error;
        });
    },

    async getSubgroupReportOptions() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/auditor/usersubgroups/reports/options`;
      await this.$axios
        .post(url, { usersubgroup: this.usersubgroup_id })
        .then((response) => {
          this.subgroup_reports_data = response.data.subgroup_reports_data;
        })
        .catch((error) => {
          error;
        });
    },
    async getUsergroupsAndInstitutions() {
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/auditor/usergroups/options`;
      await this.$axios
        .post(url, { usergroup: this.usergroup_id })
        .then((response) => {
          if (this.usergroup_id) {
            this.usersubgroupsAndCourses = response.data.usersubgroupsAndCourses;
            if (this.usersubgroupsAndCourses.length > 0) {
              this.usersubgroup_id = this.usersubgroupsAndCourses[0].id;
              this.usersubgroupChanged();
            }
          } else {
            this.institutions = response.data.institutions;
            this.institutionsAuditorUsergroups = response.data.institutionsAuditorUsergroups;
            this.usergroups = response.data.usergroups;
            this.usersubgroupsAndCourses = [];
            if (this.institutions.length > 0) {
              this.institution_id = this.institutions[0].id;
              this.institutionChanged();
            }
          }
        })
        .catch((error) => {
          if (error.response)
            if (error.response.status == 422) {
              this.allPermissionsAreExpired = true;
            }
        });
      this.loadData = false;
    },

    async usergroupChanged() {
      this.usersubgroupsAndCourses = [];
      this.usersubgroup_id = null;
      this.course_id = null;
      this.subgroup_reports_data = [];
      this.course_reports_data = [];
      this.selectedCourse = null;
      this.selectedSubgroup = null;

      await this.getUsergroupsAndInstitutions();
    },
    async institutionChanged() {
      this.usersubgroupsAndCourses = [];
      this.usergroup_id = null;
      this.usersubgroupsAndCourses = [];
      this.usersubgroup_id = null;
      this.course_id = null;
      this.subgroup_reports_data = [];
      this.course_reports_data = [];

      this.selectedCourse = null;
      this.selectedSubgroup = null;

      if (this.institution_id) {
        if (this.institutionsAuditorUsergroups[this.institution_id].length > 0) {
          this.usergroup_id =
            this.institutionsAuditorUsergroups[this.institution_id][0].usergroup_id;
          this.usergroupChanged();
        }
      }
    },
    async courseChanged() {
      this.loadData = true;
      this.course_reports_data = [];

      this.selectedCourse = this.selectedSubgroupCourses.find(course => course.id == this.course_id);

      await this.getCourseReportOptions();
      this.loadData = false;
    },
    logOutAuditor() {
      localStorage.removeItem("publicUrlUser");
      localStorage.removeItem("token");
      localStorage.removeItem("userPermissions");
      localStorage.removeItem("auditorAuthToken");
      localStorage.removeItem("publicexamAuthToken");
      this.$router.push('/auditor/login');
    },
  },
};
</script>

<style >

</style>
